<template lang="html">
  <v-dialog v-model="showModal" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('label.detalhes_cancelamento') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-timeline dense class="mx-3 my-1 pt-0">
          <v-timeline-item
              :color="colorPalette"
              fill-dot
              small
              right>

            <v-card class="elevation-2">
              <v-card-title v-if="!dadosUsuarioCancelamento" class="title historico_header px-3 py-2">{{$t('message.nao_foram_encontrados_detalhes_cancelamento')}}</v-card-title>
                <v-card-title v-if="dadosUsuarioCancelamento" class="title historico_header px-3 py-2">{{$t('label.cancelado_por') + ' ' + dadosUsuarioCancelamento.nome + ' '
                  + dadosUsuarioCancelamento.sobrenome}}</v-card-title>
              <v-card-text v-if="dadosUsuarioCancelamento">
                <v-row class="px-3 py-2">
                  <span><b>{{$t('label.email')}}</b></span>
                  <span>{{': ' + dadosUsuarioCancelamento.email}}</span>
                </v-row>
                <v-row class="px-3 py-2">
                  <span><b>{{$t('label.data_solicitacao')}}</b></span>
                  <span>{{': ' + dadosUsuarioCancelamento.dtaCriacao}}</span>
                </v-row>
              </v-card-text>
            </v-card>

          </v-timeline-item>

        </v-timeline>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click.native="fechar">{{ $t('label.fechar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  data() {
    return {
      showModal: false,
      colorPalette: 'amber lighten-1', // Amarelo

    };
  },
  props: {
    dadosUsuarioCancelamento: Object,
    entidade: String,
  },
  methods: {
    open() {
      this.showModal = true;
    },
    fechar() {
      this.showModal = false;
    },
    styles() {
      return this.colorPalette;
    },
  },
};
</script>
